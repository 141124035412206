import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageBuilder from '../components/PageBuilder'
import Container from '../components/container'

import { blockContentToText } from '../utilities/helpers'

import Illustration1 from '../../static/images/illustration1.inline.svg'
import Illustration2 from '../../static/images/illustration2.inline.svg'
import Illustration3 from '../../static/images/illustration3.inline.svg'
import Illustration4 from '../../static/images/illustration4.inline.svg'
import Illustration5 from '../../static/images/illustration5.inline.svg'
import Illustration6 from '../../static/images/illustration6.inline.svg'
import Illustration7 from '../../static/images/illustration8.inline.svg'

const IndexPage = props => {
  const { data } = props

  const {
    maintenance
  } = data?.settings

  const {
    title,
    lead,
    pageBuilder,
    _rawPageBuilder
  } = data?.settings?.homepage

  return (
    <Layout>
      <Seo
        title={title}
        description={blockContentToText(lead)}
      />

      <Container className={`bg-blue-light ${ maintenance ? '' : 'mb-[60px] md:mb-[80px]'}`} stretch={true}>
        <Header />
        <Container stretch={true} className={`overflow-hidden`}>
          <div className={`lg:max-w-screen-2xl mx-auto md:px-[15px] lg:px-[30px]`} role="presentation">
            <div className={`grid grid-rows-5 md:grid-rows-5 grid-cols-6 md:grid-cols-12 md:pb-[60px] md:max-h-[800px]`} tabIndex="0" role="presentation">
              <div className={`flex justify-center items-center row-start-1 row-span-3 col-start-1 col-span-5 md:row-start-2 md:row-span-4 md:col-start-1 md:col-span-4`}>
                <Illustration1 className={`w-full max-h-full`} />
              </div>
              <div className={`flex justify-center items-center row-start-4 row-span-2 col-start-1 col-span-3 md:row-start-3 md:row-span-3 md:col-start-5 md:col-span-3`}>
                <Illustration2  className={`w-full max-h-full`} />
              </div>
              <div className={`hidden md:flex justify-center items-center md:row-start-2 md:row-span-4 md:col-start-8 md:col-span-3`}>
                <Illustration3  className={`w-full max-h-full`} />
              </div>
              <div className={`translate-x-[10px] md:translate-x-none flex justify-center items-center row-start-4 row-span-1 col-start-4 col-span-full md:row-start-1 md:row-span-2 md:col-start-5 md:col-span-4`}>
                <Illustration4  className={`w-full max-h-full`} />
              </div>
              <div className={`hidden md:flex justify-center items-center md:row-start-4 md:row-span-2 md:col-start-11 md:col-span-2`}>
                <Illustration5  className={`w-full max-h-full`} />
              </div>
              <div className={`mt-[12%] hidden md:flex justify-center items-center md:row-start-1 md:row-span-2 md:col-start-10 md:col-span-4`}>
                <Illustration6  className={`w-full max-h-full`} />
              </div>
              <div className={`z-0 md:-translate-y-[15%] flex justify-center items-center row-start-3 row-span-2 md:row-start-2 md:row-span-2 col-start-3 col-span-3 md:col-start-4 md:col-span-3`}>
                <Illustration7  className={`w-full max-h-full`} />
              </div>
            </div>
            <div className={`max-w-screen-lg mx-auto mb-[30px] md:mb-[60px]`}>
              <div className={`px-[15px] md:px-[30px]`}>
                <p className={`text-center font-bold`}>
                  Akershus Festning<br/>
                  31. august kl. 11:00-16:00
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Container>

      { maintenance === false && (
        <PageBuilder pageBuilder={pageBuilder} _rawPageBuilder={_rawPageBuilder} />
      ) }

      <Footer />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    settings: sanitySettings {
      maintenance
      homepage {
        title,
        lead: _rawLead(resolveReferences: {maxDepth: 10})
        ...PageBuilderFields
      }
    }
  }
`

